import {
    get,
    post
} from '@/common/request'

export function getNewsList() {
    return get('news/get_news_list.php')
}

export function getNews(id) {
    return post('news/get_news.php', {
        id: id
    })
}