<template>
  <main id="NewsPage">
    <div class="row">
      <article class="main_article" v-if="article_data != null">
        <header>
          <div class="tag_group">
            <span class="tag">{{ article_data.category }}</span>
            <time>{{ article_data.create_time.slice(0, 10) }}</time>
          </div>
          <h2>{{ article_data.title }}</h2>
        </header>
        <div class="content" v-html="article_data.description"></div>
        <div class="footer">
          <p>分享此文章</p>
          <div class="button_group">
            <button @click="ShareToFB" class="facebook_share">Facebook</button>
            <button @click="ShareToLine" class="line_share">LINE</button>
            <button @click="CopyLink" class="cpoy_link">複製連結</button>
            <input hidden id="UrlInput" :value="url" />
          </div>

          <router-link to="/news/list/all" class="back_btn link_mark"
            ><span class="material-icons"> arrow_left </span
            >回到最新消息列表</router-link
          >
        </div>
      </article>
    </div>
    <MainFooter />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import MainFooter from '@/components/MainFooter';
import { getNews } from '@/api/news.js';
import { GetMetaData } from '@/common/meta';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'NewsPage',
  mixins: [SmoothScrollResister],
  components: {
    MainFooter,
  },
  data() {
    return {
      article_data: null,
      url: window.location.href,
      metainfo_title: '',
      metainfo_description: '',
      meta_data: null,
    };
  },
  methods: {
    async GetNewsData() {
      getNews(this.$route.params.id).then((res) => {
        console.log(res);
        let data = res.data;

        if (data == null) {
          ReturnErrorPage();
        } else {
          this.article_data = data;
          this.metainfo_title = this.article_data.title;
          this.metainfo_description = this.article_data.description.replace(
            /<[^>]+>/g,
            ''
          );
          this.meta_data = GetMetaData(
            this.metainfo_title,
            this.metainfo_description,
            ''
          );
          this.$nextTick(() => {
            window.prerenderReady = true;
          });
        }
      });
    },
    ShareToFB() {
      window
        .open(
          `https://www.facebook.com/sharer.php?u=${window.location.href}`,
          '_blank'
        )
        .focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
    CopyLink() {
      /* Copy the text inside the text field */
      // navigator.clipboard.writeText(window.location.href);
      var copyText = document.getElementById('UrlInput');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');

      /* Alert the copied text */
      alert('已複製連結至剪貼簿');
    },
  },
  created() {
    this.GetNewsData();
  },
  mounted() {
    this.RegisterGsap('NewsPage');
    this.$emit('init-cursor');
    this.$emit('toggle-go-top', true);
  },

  metaInfo() {
    return this.meta_data;
  },
};
</script>
